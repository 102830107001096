/* src/components/Banner.css */

.banner {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.slide {
    position: relative;
}

.banner-image {
    width: 100%;
    height: 661px;
    /* Set a fixed height for the banner */
    object-fit: cover;
    /* Ensure the image covers the entire area */
}

.caption {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.2em;
}