.visit-store-digitally {
    padding: 20px;
}

.carousel-section {
    margin-bottom: 20px;
}

.carousel-slide {
    position: relative;
}

.carousel-image {
    width: 100%;
    height: auto;
}

.carousel-caption {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
}

.stores-section {
    margin-top: 20px;
}

.stores-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.store-card {
    width: calc(33.333% - 20px);
    box-sizing: border-box;
}

.store-image {
    width: 100%;
    height: auto;
}

.store-info {
    text-align: center;
    padding: 10px;
}