/* Reset body and html styles */
/* html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
} */

/* Ensure these settings are not blocking scroll */
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* Allows vertical scrolling */
}

/* Flexbox layout for the container */
.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Main content area */
.main-content {
  flex: 1;
}

/* Footer styling */
footer {
  margin: 0;
  padding: 1rem;
  text-align: center;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  display: block;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}

.page-link:hover {
  background-color: #007bff;
  color: #fff;
}

.page-item.active .page-link {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.logo {
  max-width: 180px;
}

/* 
.multi-select .dropdown-heading-value {
  display: none;
}

*/
/* Add this to your CSS file or use inline styles */
.multi-select .clear-selected-button {
  display: none !important;
  /* Forcefully hides the clear button */
}

/* MultiSelectColor.css */

/* Hide checkbox input */
.multi-select-item input[type="checkbox"] {
  display: none;
}

/* Add to your NavMenu CSS file */

/* Style for active menu item */
.nav-menu li.active>a {
  color: #038DCE;
}

/* Optional: style for active item background */

/* Style for active sub-category item */
.nav-menu li .mega-menu .col-2 ul li.active>a {
  color: #038DCE;
}

/* Optional: style for active sub-category item background */
.nav-menu li .mega-menu .col-2 ul li.active {
  background-color: #f8f9fa;
  /* Change to your desired background color */
}

.sub-category-list li.active {
  color: #03a1df;
}







.profile-menu {
  position: relative;
}

.profile-menu ul {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  list-style: none;
  margin: 0;
  width: 150px;
  display: none;
  /* Hidden by default */
  z-index: 10;
}

.profile-menu:hover ul {
  display: block;
  /* Show dropdown on hover */
}

.profile-menu button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

ul.nav-menu>li:hover>a:before,
ul.nav-menu>li.active>a:before {
  content: '';
  position: absolute;
  height: 1px;
  background: #038fd1;
  width: 100%;
  bottom: 28px;
}

.coupons ul li .status .cp-sts {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: red;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 20px 0px 0px 20px;
}



/*megamenu css start*/
.mega-menu .row {
  display: flex;
  flex-wrap: wrap;
}

.mega-menu .col-3 {
  width: 25%; /* 100% / 4 columns = 25% width each */
 padding: 2px 10px;
}

.mega-menu .col-3 a {
  display: block;
  text-align:left;
  border-radius: 5px;
  margin-bottom: 10px;
color: black;
text-decoration:none;
font-size: 14px
}
.mega-menu .col-3.active a
{
color:#038dce;
}

/*footer changes start*/
.footer__columns__column--social {
  display: flex;
  flex-direction: column;
}

.footer__columns__column__stores {
  font-size: 1rem;
}
.footer__columns__column__stores {
  font-family: "FuturaStd-Medium";
  font-size: .88rem;
  line-height: 130%;
  letter-spacing: 0;
}
@media (min-width: 1024px)
{

.footer__columns__column__stores__container {
  margin: 12px 0 15px;
}
.footer__columns__column__networks__list__item {
  margin: 0 5px;
}
  .footer__columns__column__networks__list__item {
  font-size: 2.13rem;
}
}
@media (min-width: 600px)
{
.footer__columns__column__stores__container {
  margin: 18px 0 36px;
}
.footer__columns__column__stores__badge {
  max-width: 120px;
  margin: 0 12px;
}
.footer__columns__column__networks {
  font-size: 1rem;
}
.footer__columns__column__networks__list__item {
  font-size: 1.88rem;
}
}
.footer__columns__column__stores__container {
  margin: 21px 0 42px;
  display: flex;
  justify-content: center;
}
.footer__columns__column__stores__badge {
  width: 48%;
  margin: 0 6px;
  max-width: 158px;
}
.footer__columns__column__stores__image {
  width: 100%;
}
.footer a {
  color: inherit;
  text-decoration: underline;
}
.footer__columns__column__networks {
  font-family: "FuturaStd-Medium";
  font-size: .88rem;
  line-height: 130%;
  letter-spacing: 0;
}
.footer__columns__column__networks__list {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.icon__linkedin:before {
  content: "\f802";
}
.icon__facebook:before {
  content: "\f809";
}
.icon__twitter:before {
  content: "\f803";
}
.icon__pinterest:before {
  content: "\f807";
}
.icon__instagram:before {
  content: "\f804";
}
.icon__youtube:before {
  content: "\f806";
}
.icon__youtube:before {
  content: "\f806";
}
.icon__wechat:before {
  content: "\f805";
}
/*footer changes end*/