/* src/components/WeddingStore.css */
.wedding-store {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.slide {
    position: relative;
}

.wedding-image {
    width: 100%;
    height: auto;
}

.caption {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    border-radius: 5px;
}